import { Link, Picture } from "@/components/utilities";

export default function PostCard({ item, bg = "bg-white dark:bg-dark-100" }) {
  return (
    <article
      className={`
        flex flex-col rounded-sm overflow-hidden
        ${
          " " // item.status === "preview" ? "opacity-50 pointer-events-none" : ""
        }
        ${bg}
      `}
      style={{ minHeight: "15rem" }}
    >
      {item.image ? (
        <div className="flex-shrink-0 bg-violet-100">
          <Link href={`${item.url}`} block>
            <Picture
              src={item.image.src}
              width={item.image.width}
              height={item.image.height}
              sizes="100vw, (min-width: 768px) 50vw, (min-width: 1024px) 33vw"
              className="h-48 w-full object-cover"
              alt={item.image.alt || null}
            />
          </Link>
        </div>
      ) : null}
      <div className="flex-1">
        <Link
          href={`${item.url}`}
          className="h-full flex flex-col items-stretch justify-between"
        >
          <header className="flex-1 p-6 space-y-2">
            {item.collection ? (
              <span className="block text-xs font-medium text-brand-300 font-pixel uppercase">
                {item.collection}
              </span>
            ) : null}
            <div className="block mt-2">
              {item.title ? (
                <h2 className="text-xl font-bold text-1">{item.title}</h2>
              ) : null}
              {item.lede ? (
                <p className="mt-2 text-base text-3">{item.lede}</p>
              ) : null}
            </div>
          </header>
          <footer className="p-6 pt-0 flex items-end justify-between space-x-4">
            <div className="flex items-center flex-shrink-0 space-x-2">
              <div className="flex-shrink-0 flex -space-x-2">
                {/* {item.authors?.map((author, index) => (
                  <Image
                    key={index}
                    media={author.avatar}
                    className="h-8 w-8 rounded-sm border-2 border-violet-20 dark:border-violet-900"
                    alt={`${author.name} Profile Image`}
                  />
                ))} */}
              </div>
              <p className="text-xs font-medium text-gray-600 dark:text-violet-50">
                {item.authors?.map((author, index) => (
                  <span key={index}>
                    {index === item.authors.length - 1 ? " & " : ""}
                    {author.name}
                    {item.authors.length > 1 &&
                    (index === 0 || index >= item.authors.length - 2) ? (
                      <br />
                    ) : (
                      `,${(<br />)}`
                    )}
                  </span>
                ))}
              </p>
            </div>

            <div className="flex space-x-1 text-xs leading-4 text-gray-500 dark:text-violet-100">
              {item.updated ? (
                <time dateTime={item.updated}>{formatDate(item.updated)}</time>
              ) : null}
              {/* <span aria-hidden="true">·</span> */}
              {/* <span>6 min read</span> */}
            </div>
          </footer>
        </Link>
      </div>
    </article>
  );
}

import { Title, Body } from '@/components/typography';
import { Link, Button, Icon } from '@/components/utilities';

const DashboardItem = ({
  icon = null,
  iconColor = 'text-brand-green',
  title,
  description,
  url,
  cta,
}) => {
  return (
    <div className="group w-full bg-white dark:bg-dark-200 group-hover:opacity-75 hover:!opacity-100 rounded-sm flex flex-col items-stretch justify-between transition-opacity">
      <Link block href={url} className="mb-auto p-3 sm:p-4 lg:p-6">
        <div className="flex items-center space-x-4">
          <Icon icon={icon} className={`w-8 h-8 ${iconColor}`} />
          <Title as="h2" size="lg" className="font-code text-1">
            {title}
          </Title>
        </div>
        <Body size="sm" className="mt-3 text-3">
          {description}
        </Body>
      </Link>
      {cta ? (
        <div className="p-3 sm:p-4">
          <Button href={url} label={cta} secondary size="xs" />
        </div>
      ) : null}
    </div>
  );
};
export default DashboardItem;

import React from "react";

const Code = ({
  children,
  as = "code",
  size = "sm",
  primary,
  secondary,
  muted,
  className = "",
}) => {
  let font;
  if (size === "xs") font = "text-xs leading-4";
  if (size === "sm") font = "text-sm leading-6";
  if (size === "md") font = "text-base leading-6";
  if (size === "lg") font = "text-lg leading-8";
  if (size === "xl") font = "text-xl leading-8";
  if (size === "2xl") font = "text-2xl leading-10";
  if (size === "3xl") font = "text-3xl leading-10";

  let color = "text-gray-700 dark:text-purple-300";
  if (primary) color = "text-gray-900 dark:text-purple-50";
  if (secondary) color = "text-gray-500 dark:text-purple-200";
  if (muted) color = "text-gray-400 dark:text-purple-400";

  if (!children) return null;

  return React.createElement(
    as,
    {
      className: `font-code ${font} ${color} ${className}`,
    },
    children
  );
};

export default Code;

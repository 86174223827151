const ITTYBIT_API_VERSION = 'latest';
const mode = (
  process.env.MODE ||
  process.env.NEXT_PUBLIC_MODE ||
  'production'
).toLowerCase();

console.log({ mode });

function getAppUrl() {
  if (mode === 'production') return 'https://ittybit.com';
  if (mode === 'staging') return 'https://ittybit.dev';
  return 'http://localhost:3000';
}

function getApiUrl() {
  if (mode === 'production') return 'https://api.ittybit.com';
  if (mode === 'staging') return 'https://api.ittybit.dev';
  return 'http://localhost:8124';
}

function getPlayerUrl() {
  if (mode === 'production') return 'https://player.ittybit.com';
  if (mode === 'staging') return 'https://player.ittybit.dev';
  return 'http://localhost:4000';
}

function getHeaders({ token = null }) {
  const headers = {
    'Content-Type': 'application/json',
    // "Ittybit-Version": ITTYBIT_API_VERSION,
  };
  if (token) {
    headers['Ittybit-Token'] = token;
  }
  return headers;
}

async function apiRequest({ url, options }) {
  try {
    let response = await fetch(url, { cache: 'no-store', ...options });
    let json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function getRequest({ token, url, queryParams = null, config = {} }) {
  let options = {
    method: 'GET',
    headers: getHeaders({ token }),
    ...config,
  };
  return apiRequest({ url, options });
}

export function postRequest({ token, url, payload = null, config = {} }) {
  let options = {
    method: 'POST',
    headers: getHeaders({ token }),
    ...config,
  };
  if (payload) {
    options.body = JSON.stringify(payload);
  }
  return apiRequest({ url, options });
}

export function deleteRequest({ token, url, config = {} }) {
  let options = {
    method: 'DELETE',
    headers: getHeaders({ token }),
    ...config,
  };
  return apiRequest({ url, options });
}

export const API_BASE_URL = getApiUrl();
export const APP_BASE_URL = getAppUrl();
export const PLAYER_BASE_URL = getPlayerUrl();
export const STATIC_BASE_URL = 'https://ittybit-app.ittybitcdn.com';

export const SOURCE_KINDS = ['image', 'video', 'audio'];
export const INTELLIGENCE_KINDS = [
  'faces',
  'text',
  'objects',
  'speech',
  'nsfw',
  'summary',
  'description',
  'tags',
  'sentiment',
];
export const TRACKS_KINDS = ['chapters', 'thumbnails', 'subtitles', 'captions'];

export const endpoints = {
  ip: `${API_BASE_URL}/ip`,
  login: `${API_BASE_URL}/login`,
  users: `${API_BASE_URL}/users`,
  user: `${API_BASE_URL}/user`,
  organisations: `${API_BASE_URL}/organisations`,
  organisation: `${API_BASE_URL}/organisations/:id`,
  projects: `${API_BASE_URL}/projects`,
  project: `${API_BASE_URL}/projects/:id`,
  billing: `${API_BASE_URL}/billing`,
  billingStats: `${API_BASE_URL}/billing/stats`,
  billingInvoices: `${API_BASE_URL}/billing/invoices`,
  billingUrl: `${API_BASE_URL}/billing/portal`,
  checkout: `${API_BASE_URL}/checkout`,
  keys: `${API_BASE_URL}/keys`,
  key: `${API_BASE_URL}/keys/:id`,
  logs: `${API_BASE_URL}/logs`,
  log: `${API_BASE_URL}/logs/:id`,
  tasks: `${API_BASE_URL}/tasks`,
  task: `${API_BASE_URL}/tasks/:id`,
  automations: `${API_BASE_URL}/automations`,
  automation: `${API_BASE_URL}/automations/:id`,
  workflows: `${API_BASE_URL}/workflows`,
  workflow: `${API_BASE_URL}/workflows/:id`,
  webhooks: `${API_BASE_URL}/webhooks`,
  webhook: `${API_BASE_URL}/webhooks/:id`,
  uploads: `${API_BASE_URL}/uploads`,
  media: `${API_BASE_URL}/media`,
  item: `${API_BASE_URL}/media/:id`,
  files: `${API_BASE_URL}/files`,
  folders: `${API_BASE_URL}/folders`,
  folder: `${API_BASE_URL}/folders/:folder`,
};

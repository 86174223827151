import { Body, Title } from "@/components/typography";

const Toast = ({
  type = "info",
  title,
  body,
  primary,
  secondary,
  fixed = false,
}) => {
  let bgColor = "bg-blue-100";
  let textColor = "text-blue-900";
  if (type === "success") {
    bgColor = "bg-green-100";
    textColor = "text-green-900";
  }
  if (type === "warning") {
    bgColor = "bg-yellow-100";
    textColor = "text-yellow-900";
  }
  if (type === "error") {
    bgColor = "bg-red-100";
    textColor = "text-red-900";
  }

  return (
    <div className={fixed ? `fixed top-0 left-0 right-0` : ""}>
      <div
        className={`flex items-center justify-between space-x-4 md:max-w-screen-sm mx-auto p-3 text-center font-medium ${bgColor} ${textColor}`}
      >
        <div
          className={primary || secondary ? "text-left" : "text-center w-full"}
        >
          <Title size="sm">{title}</Title>
          <Body size="sm">{body}</Body>
        </div>
        {primary || secondary ? (
          <div>
            {secondary} {primary}
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Toast;

import NextLink from 'next/link';

const Link = ({
  children,
  id = null,
  href = null,
  disabled,
  block,
  className = '',
  target,
  scroll = true,
}) => {
  let isInternal = false;
  let isAnchor = false;
  if (
    href?.[0] === '/' ||
    href?.includes('localhost') ||
    href?.includes('ittybit.local') ||
    href?.includes('ittybit.com') ||
    href?.startsWith('#')
  ) {
    isInternal = true;
  }
  if (href?.startsWith('#')) {
    isAnchor = true;
  }
  if (target === '_blank') {
    isInternal = false;
  }

  let disable = '';
  if (disabled) disable = 'pointer-events-none opacity-50';

  let classNames = `${block ? 'block' : ''} ${disable} ${className}`;

  if (isAnchor) {
    return (
      <a id={id} href={href} className={classNames}>
        {children}
      </a>
    );
  }
  if (isInternal) {
    return (
      <NextLink id={id} href={href} passHref scroll={scroll}>
        <span className={classNames}>{children}</span>
      </NextLink>
    );
  }
  return (
    <a
      id={id}
      href={href}
      className={classNames}
      target="_blank"
      rel="noopener">
      {children}
    </a>
  );
};

export default Link;

'use client';

import { getCode } from '@/api/jsx';
import { useState, useEffect, Suspense } from 'react';
import { CopyButton } from '@/components/utilities';

const CodeBlock = ({
  title = null,
  language = 'json',
  code = '',
  src = null,
  copy = false,
  lineNumbers = true,
  className = '',
}) => {
  const [existing, setExisting] = useState(null);
  const [component, setComponent] = useState(null);

  useEffect(() => {
    if (existing === code) return;
    async function updateComponent({
      code,
      src,
      language,
      title,
      lineNumbers,
    }) {
      if (!code && src) {
        try {
          const res = await fetch(src);
          if (language === 'json') {
            code = JSON.stringify(await res.json(), null, 2);
          } else {
            code = await res.text();
          }
        } catch (error) {
          console.warn({ error });
          return null;
        }
      }
      try {
        const newCode = await getCode({
          code,
          language,
          title,
          lineNumbers,
        });
        setComponent(newCode);
        return;
      } catch (error) {
        console.warn({ error });
        return;
      }
    }
    updateComponent({ code, src, language, title, lineNumbers });
    setExisting(code);
  }, [code, src]);

  return (
    <div className={`codeblock ${className}`}>
      <figure
        className={`group relative ch-codeblock block ${
          lineNumbers ? 'ch-linenumbers' : ''
        }`}>
        <Suspense
          fallback={
            <p className="text-5 text-sm bg-black p-4">Generating code ...</p>
          }>
          {code && existing && component ? component : null}
          {src && component ? component : null}
        </Suspense>
        {copy ? (
          <div
            className={`absolute ${
              title ? 'top-8 lg:top-10' : 'top-0'
            } right-0 z-20`}>
            <CopyButton
              value={code}
              className="opacity-75 hover:!opacity-100 transition-opacity"
            />
          </div>
        ) : null}
      </figure>
    </div>
  );
};

export default CodeBlock;

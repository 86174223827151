'use client';

import { useRef, useState } from 'react';
import { Icon } from '@/components/utilities';

const CopyButton = ({ value, className = '' }) => {
  const ref = useRef(null);
  const [label, setLabel] = useState('Copy');
  const [icon, setIcon] = useState('copy');
  function copyValue() {
    try {
      ref.current.focus();
      ref.current.select();
      document.execCommand('copy');
    } catch (err) {
      console.warn('Problem copying :(');
    }
    setIcon('check');
    setLabel('Copied');
    setTimeout(() => {
      setIcon('copy');
      setLabel('Copy');
    }, 1000);
  }
  return (
    <div className={className}>
      <button
        onClick={copyValue}
        type="button"
        className="bg-white bg-opacity-50 hover:bg-violet-50 hover:bg-opacity-100 
    dark:bg-opacity-5 dark:hover:bg-opacity-10 flex items-center space-x-3 text-3 hover:text-1 px-1">
        <span className="flex-col items-center justify-center text-xs leading-6">
          {label}
        </span>
        <Icon icon={icon} className="w-5 h-5 opacity-75" />
      </button>
      <input
        ref={ref}
        defaultValue={value}
        className="fixed right-0 top-0 h-0 overflow-hidden"
      />
    </div>
  );
};

export default CopyButton;

'use client';
import { useState } from 'react';
import { Button } from '@/components/utilities';

export default function RequestAccess({ id }) {
  const storedRequested =
    typeof localStorage !== 'undefined' ? localStorage.getItem(id) : false;

  const [requested, setRequested] = useState(storedRequested);

  function requestAccess() {
    setRequested(true);
    // TODO: Send request to server
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(id, 'true');
    }
    window.alert("Thanks for your interest! We'll be in touch soon.");
  }

  if (requested) {
    return (
      <Button
        onClick={() => null}
        label="Access requested"
        disabled
        secondary
      />
    );
  }

  return (
    <Button onClick={requestAccess} label="Request early access" primary />
  );
}

'use client';

import { useEffect, useState } from 'react';
import { CodeBlock, Button } from '@/components/utilities';
import { endpoints, getRequest } from '@/api';

async function getKeys({ token, org_id, project_id }) {
  let response = await getRequest({
    token: token,
    url: `${endpoints.keys}?org_id=${org_id}&project_id=${project_id}`,
  });
  return response;
}

const CodeExample = ({ request, response, user, project }) => {
  const [requestCode, setRequestCode] = useState(request.code);
  const [keys, setKeys] = useState(null);
  const [apiStatus, setApiStatus] = useState('isReady');

  useEffect(() => {
    if (user && project) {
      getKeys({
        token: user.token,
        org_id: project.org_id,
        project_id: project.id,
      }).then(({ data, error }) => {
        console.log({ data, error });
        if (error) {
          console.error(error);
          return;
        }
        if (data) {
          setKeys(data);
        }
      });
    }
  }, [user, project]);

  useEffect(() => {
    if (keys) {
      console.log({ keys });
      let code = requestCode;
      let key = keys.find(key => key.read);
      if (key) {
        code = code.replace(`YOUR_API_KEY`, key.key);
      }
      setRequestCode(code);
    }
  }, [keys, request.code]);

  function runApi() {
    setApiStatus('isLoading');
    setTimeout(() => {
      setApiStatus('isSuccess');
    }, 800);
  }
  return (
    <div className="space-y-6">
      {request ? (
        <CodeBlock
          language={request.language}
          title={request.title}
          copy={request.copy}
          code={requestCode}
        />
      ) : null}
      {response ? (
        <>
          {apiStatus === 'isReady' ? (
            <Button
              onClick={runApi}
              icon="play"
              label="Run"
              className="w-[fit-content]"
              size="sm"
              disabled={apiStatus !== 'isReady'}
              primary
              // reverse
            />
          ) : (
            <CodeBlock
              language={apiStatus === 'isSuccess' ? response.language : 'json'}
              title={response.title}
              copy={response.copy}
              code={
                apiStatus === 'isSuccess' ? response.code : '// Fetching...'
              }
            />
          )}
        </>
      ) : null}
    </div>
  );
};

export default CodeExample;

"use client";

import { useRouter } from "next/navigation";
import { Icon } from "@/components/utilities";

export default function Modal({
  children,
  href,
  onClick = () => null,
  padding = true,
}) {
  const router = useRouter();
  if (href) {
    onClick = () => router.push(href);
  }

  return (
    <div
      className="modal fixed inset-0 w-screen h-screen z-40 bg-white/80 dark:bg-dark-100/80 flex items-start justify-center py-20 px-4"
      onClick={onClick}
    >
      <div
        className={`relative z-50 max-w-screen-md w-full max-h-[calc(100vh_-_160px)] overflow-y-auto bg-white dark:bg-dark-50 rounded-sm ${
          padding ? "p-4 sm:p-6 md:p-8 lg:p-12" : ""
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
      <button
        className="absolute top-16 right-0 mt-3 mr-5 text-3 hover:text-1"
        onClick={onClick}
      >
        <Icon icon="close" className="w-8 h-8" />
      </button>
    </div>
  );
}

import { CodeBlock, Link } from '@/components/utilities';
import React from 'react';
import Markdown from 'react-markdown';

function flatten(text, child) {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text);
}

function CodeRenderer({ children: c = null }) {
  if (!c) return null;
  return React.createElement('code', null, c);
}

function PreRenderer({ children: c = null }) {
  if (!c) return null;
  // return <CodeBlock language="js" code={JSON.stringify(c, null, 2)} />;
  return React.createElement('pre', null, c);
}

function HeadingRenderer({ as = 'h2', children: c }) {
  const children = React.Children.toArray(c);
  const text = children.reduce(flatten, '');
  const slug = text.toLowerCase().replace(/\W/g, '-');
  return React.createElement(as, { id: slug }, c);
}

const RichText = ({ children = null }) => {
  if (!children) return null;
  return (
    <Markdown
      components={{
        a: Link,
        pre: PreRenderer,
        code: CodeRenderer,
        h1: HeadingRenderer,
        h2: props => HeadingRenderer({ ...props, as: 'h3' }),
        h3: props => HeadingRenderer({ ...props, as: 'h4' }),
      }}>
      {children}
    </Markdown>
  );
};

export default RichText;

'use client';

import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { useEffect } from 'react';

const BentoScript = ({ user }) => {
  const pathname = usePathname();

  useEffect(() => {
    if (window?.bento !== undefined) {
      if (user) {
        // console.log("bento.identify");
        window.bento.identify(user.email);
        window.$bentoChat?.setUser(user.id, {
          email: user.email,
          name: user.name,
          identifier_hash: user.bentoHash,
        });
        // console.log("bentoChat.setUser");
        // console.log(user.id, user.bentoHash);
      }
    }
  }, [user]);

  useEffect(() => {
    if (window?.bento !== undefined) {
      // console.log("b.view");
      window?.bento.view();
    }
  }, [pathname]);

  return (
    <>
      <Script id="bento-chat" strategy="afterInteractive">
        {`window.addEventListener('bento:ready', function () {
      (function(d,t) {
        var BASE_URL="https://chat.bentonow.com";
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src=BASE_URL+"/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
          window.bentoChatSDK.run({
            websiteToken: 'hggmJX9tJupi8iM6gd4prnMD',
            baseUrl: BASE_URL
          })
        }
      })(document,"script");
      });`}
      </Script>
    </>
  );
};

export default BentoScript;

import React from "react";

const Title = ({
  children,
  as = "h1",
  size = "xl",
  primary,
  secondary,
  className = "",
}) => {
  let font;
  if (size === "xs") font = "text-3xs sm:text-2xs md:text-xs ";
  if (size === "sm") font = "text-2xs sm:text-xs md:text-sm";
  if (size === "md") font = "text-xs sm:text-sm md:text-base ";
  if (size === "lg") font = "text-sm sm:text-base md:text-lg";
  if (size === "xl") font = "text-md sm:text-lg md:text-xl ";
  if (size === "2xl") font = "text-lg sm:text-xl md:text-2xl";
  if (size === "3xl") font = "text-xl sm:text-2xl md:text-3xl ";

  let weight = "font-medium";
  if (primary || secondary) weight = "font-bold";

  return React.createElement(
    as,
    {
      className: `${font} ${weight} ${className}`,
    },
    children
  );
};

export default Title;

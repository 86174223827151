const Badge = ({ value, size = 'md', className = '' }) => {
  if (!value) {
    return null;
  }
  let sizes = 'text-code-base';
  if (size === 'sm') {
    sizes = 'text-code-sm';
  } else if (size === 'lg') {
    sizes = 'text-code-lg';
  } else if (size === 'xl') {
    sizes = 'text-code-xl';
  }
  return <span className={`uppercase ${sizes} ${className}`}>{value}</span>;
};

export default Badge;
